
/*
    INFORMACIÓN
	- File:           trejocode.scss
	- Versión:        1.1.4
	- Licence:        MIT
	- Author:         DWIT - TrejoCode
    - Update:         19/10/2018
    - Client:         Elfos Gourmet
*/


/* PROPERTIES
   =============================

	1. = COLORS
	2. = FONT FAMILY
    3. = FONT SIZE
    4. = FONT WEIGHT
	5. = PADDINGS
    6. = BORDERS

*/



    /*    COLORS    */


    $white:        #FFFFFF;
    $red:          #E74C3C;
    $black:        #272727;
    $darkGray:     #9C9C9C;
    $lightGray:    #E9EBED;
    $softGray:     #F4F5F6;
    $green:        #4A9C62;
    //$aqua:         #1D846F;
    $aqua:         #2d7d90;
    $brown:        #B58316;
    $darkBrown:    #906811;
    $blue:         #00a6b7;
    $darkGreen:    #009b0e;
    $yellow:       #ebb602;
    $darkYellow:   #efba00;
    $pink:         #ec4193;
    $darkRed:      #C0392B;
    $gray:         #9c9c9c;

    /*    FONT FAMILIES    */


    $Roboto:   'Roboto', sans-serif;
    $Pacifico: 'Pacifico', cursive;

   
    /*    FONT SIZES    */


    $fontMini:      0.8em;
    $fontTiny:      0.9em;
    $fontText:      0.93em;
    $fontSmall:     1em;
    $fontRegular:   1.2em;
    $fontMedium:    1.4em;
    $fontLarge:     1.6em;
    $fontHuge:      1.8em;


    /*    FONT WEIGHTS 

        - Based on 'Montserrat'

    */

    
    $weightRegular: 400;
    $weightMedium:  500;
    $weightBold:    700;
    $weightBlack:   900;

    
    /*    PADDINGS, MARGINS

        This properties also applies for 'Margins'

    */


    $paddingNormal: 8px;
    $paddingGutters: 12px;
    $paddingMedium: 16px;
    $paddingSemi: 24px;
    $paddingLarge: 32px;
    $paddingHuge: 48px;


    /*    BORDER    */


    $border:       2px solid $lightGray;
    $boder-focus:  2px solid $aqua;




/* RESET
   =============================

   - These properties able the cross compatibility for browsers and removes default properties from browsers.

*/

* {
    padding: 0;
    margin: 0;
    transition: all 0.5s ease;
}

::selection {
    background: $aqua;
    color: $white;
}

html {
  box-sizing: border-box;
}

body {    
    overflow-x: hidden;
    font-family: $Roboto;
    background-color: $white;
}

button, input, select, textarea, option {
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;
}

table {    
    border-spacing: 0;
}

div, header, footer, section, article, aside, nav, form, table {
    width: 100%;
}

ul, ol {
    list-style: none;
}

a {
    text-decoration: none;
    color: inherit;
}

img {
    max-width: 100%;
    height: auto;
    object-fit: cover;
}

h1, h2, h3, h4, h5, h6 {
    color: $white;
}

h1 {
    font-size: $fontHuge;
    font-weight: $weightBold;
}

h2 {
    font-size: $fontLarge;
    font-weight: $weightBold;
}

h3 {
    font-size: $fontMedium;
    font-weight: $weightBold;
}

h4 {
    font-size: $fontRegular;
    font-weight: $weightMedium;
}

h5 {
    font-size: $fontText;
    font-weight: $weightMedium;
}

h6 {
    font-size: $fontMedium;
    font-weight: $weightRegular;
}

p {
    font-size: $fontText;
    color: $darkGray;
    line-height: 24px;
}

/* CLASES
   =============================

	1. = FLEX LAYOUT
	2. = ALIGNMENT
    3. = WHITE SPACES
    4. = FORMS
    5. = BUTTONS
    6. = DECORATORS & COLORS

*/



/*    FLEX LAYOUT

    - This stylesheet is based on flexbox.
    - W3 CSS Flexible Box Layout Module:
       https://www.w3.org/TR/css-flexbox-1
    
*/

.flex {
    display: flex;
}

.flex div, .flex img, .flex nav, .flex form {
    display: flex;
}

.container {
    width: 90%;
}

.responsive-img {
    display: flex;
    justify-content: center;
    align-items: center;
}

.row {
    flex-direction: row;
}

.row-responsive {
    flex-direction: row;
}

.column {
    flex-direction: column;
}

.auto {
    width: auto;
}

.grid {
    display: grid !important;
}

.gutters {
    margin: 0 auto;
}

.gutters > div {
    margin: $paddingGutters;
}

.gutters > div:first-of-type {
    margin-left: 0;
    color: $black;
}

.gutters > div:last-of-type {
    margin-right: 0;
}

.wrap {
    flex-wrap: wrap;
}


/*    ALIGNMENT    */


.justify-center {
    justify-content: center;
}

.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.justify-between {
    justify-content: space-between;
}

.justify-arround {
    justify-content: space-around;
}

.align-center {
    align-items: center;
}

.align-start {
    align-items: flex-start;
}

.align-end {
    align-items: flex-end;
}


/*    TEXT ALIGNMENT    */


.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-justify {
    text-align: justify;
}

.text-right {
    text-align: right;
}


/*    WHITE SPACES    */


.white-space-8 {
    height: 8px;
}

.white-space-16 {
    height: 16px;
}

.white-space-24 {
    height: 24px;
}

.white-space-32 {
    height: 32px;
}

.white-space-48 {
    height: 48px;
}

.white-space-64 {
    height: 64px;
}


/*    FORMS     */


label {
    color: $darkGray;
}

.label-error {
    color: $red;
}

/*.input {
    color: $darkGray;
    font-size: $fontMini;
    font-family: $Roboto;    
    font-weight: $weightMedium;    
}*/

.input {
    padding: 4px 12px;
    font-size: 14px;
    border: 1px solid #7da2ab;
    border-radius: .25rem;
}

.input-search {
    min-height: 45px;
    border: $border;
    border-radius: 45px 0 0 45px;
    padding-left: $paddingMedium;
}

.input-search:focus,
.input-text:focus {
    border: $boder-focus;
}

.input-cta-email {
    height: 50px;
    align-self: flex-start;    
    padding: 0 $paddingNormal;
}

.input-number {
    border: $border;
    padding: $paddingNormal 4px;
}

.input-text {    
    color: $black;
    border-radius: 4px;
    font-weight: $weightMedium;
    padding: $paddingNormal;
}

.textarea {
    color: $black;
    border: $border;
    border-radius: 4px;
    font-weight: $weightMedium;
    padding: $paddingNormal;
    line-height: 18px;   
}

.input-select {
    padding: $paddingNormal;
    color: $black;
}

.hidden {
    display: none !important;
}


/*    BUTTONS     */


.btn {
    width: auto;
    height: auto;
    outline: none;
    cursor: pointer;    
    background: none;
    color: $white;
    font-size: $fontText;
    font-family: $Roboto;
    font-weight: $weightBold;
    padding: 11px 24px;
}

.btn:active {
    transform: scale(0.96);
}

.btn-search {  
    min-width: 20%;
    color: #9c9c9c;
    border: $border;
    border-radius: 0 45px 45px 0px;
    border-left: 1px solid $lightGray;
    transition: all 0.2s;
}

.btn-search:active, .btn-search:hover  {
    border: $boder-focus;
    color: $aqua;
}

.btn-green {
    background: $darkGreen;
    border-radius: 4px;
}

.btn-green:hover {
    background: #4a9c62cf;
}

.btn-yellow {
    background: $yellow;
    border-radius: 4px;
}

.btn-aqua {
    border-radius: 4px;
    background: #10a7b5;
}

.btn-aqua:hover {
    background: $aqua;
}

.btn-brown {
    background: $brown;    
}

.btn-brown:hover {
    background: $darkBrown;
}

.btn-red {
    border-radius: 4px;
    background-color: $red;
}

.btn-red:hover {
    background-color: #C0392B;
}

.btn-blue {
    background: $blue;
}

.btn-blue:hover {
    background: #2d7d90;
}

.btn-orange {
    background: #10a7b5;
    border-radius: 4px;
}

.btn-orange:hover {
    background: #2d7d90;
}

.btn-gray {
    background: $gray;
    border-radius: 4px;
}

.btn-darkRed {
    background: $darkRed;
    border-radius: 4px;
}

.btn-darkYellow {
    background: $darkYellow;
    border-radius: 4px;
}

.btn-pink {
    background:  $pink;
    border-radius: 4px;
}

.link {
    cursor: pointer;
}

.link:hover {
    mask-image: linear-gradient(-75deg, rgba(0,0,0,.9) 30%, #000 50%, rgba(0,0,0,.6) 70%);
    mask-size: 200%;
    animation: shine 1.5s infinite;
}

@keyframes shine {
  from {
    -webkit-mask-position: 150%;
  }

  to {
    -webkit-mask-position: -50%;
  }
}

.table-responsive {
    overflow-x: auto;
}


/*    DECORATORS & COLORS    */


.bordered {
    border: $border;
}

.pacifico {
    font-family: $Pacifico;
}

.color-white {
    color: $white;
}

.color-lightGray {
    color: $lightGray;
}

.color-darkGray {
    color: $darkGray;
}

.category-selected {
    color: $brown !important;
    font-weight: $weightBold;
}

.color-black {
    color: $black;
}

.color-aqua {
    color: $aqua;
}

.color-green {
    color: $darkGreen;
}

.color-orange {
    color: orange;
}

.color-gray {
    color: gray;
}

.color-blue {
    color: $blue;
}

.weight-medium {
    font-weight: $weightMedium;
}

.weight-bold {
    font-weight: $weightBold;
}

.uppercase {
    text-transform: uppercase;
}

.text-mini {
    font-size: $fontMini;
}

.pointer {
	cursor: pointer;
}

/*    LARGE DEVICES    */

@media screen and (min-width: 1440px) {

    .container {
        max-width: 1440px;
    }

}

@media screen and (max-width: 1200px) {
   body {
        background: lightcoral;
    }
}

/*    NOTEBOOKS, IPADS DEVICES    */

@media screen and (max-width: 992px) {

    body {
        background: lightblue;
    }

    .break-ipad {
        flex-direction: column;
    }
}

/*    TABLETS DEVICES    */

@media screen and (max-width: 768px) {    
    
    body {
        background: white;
    }

    .row-responsive {
        flex-direction: column;
    }

    .white-space-64 {
        height: 32px;
    }

    .gutters > div {
        margin: 0;
    }
    
}

/*    MOBILE DEVICES    */

@media screen and (max-width: 576px) {
    
    body {
        background: dimgray;
    }

    .input-search {
        min-height: 38px;
        margin-left: -8px;        
        padding-left: $paddingNormal;
    }
    
}
